import moment from "moment";

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatDate = (date) => {
  if (date) {
    return moment(date).format("DD-MM-YYYY");
  }
};
