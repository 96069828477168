import React from "react";
import { Redirect } from "react-router-dom";

import Logout from "../pages/Authentication/Logout";
import Login from "../pages/Authentication/Login";
import { HomePage } from "../pages/Home";

//Pages
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

const authProtectedRoutes = [
  { path: "/logout", component: Logout, exact: true },
  { path: "/pages-404", component: Pages404, exact: true },
  { path: "/pages-500", component: Pages500, exact: true },
  { path: "/home", component: HomePage, exact: true },
];

const publicRoutes = [
  { path: "/login", component: Login },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { authProtectedRoutes, publicRoutes };
