import { Button, Col, Row } from "reactstrap";
import { formatDate } from "../../../helpers/funtions";

export const Prize = ({ item, onClick }) => {
  const userName = `${item.user_id.name || ""} ${item.user_id.lastname || ""}`;
  const identification = item.user_id.identification || "";
  return (
    <Row className="d-flex justify-content-between align-items-center">
      <Col sm="12" lg="2">
        <div className="w-100 text-center">
          <img src={item.prize_id.url_logo} className="img-fluid" />
        </div>
      </Col>
      <Col sm="12" lg="10">
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div className="d-flex flex-column align-items-start">
            <p
              style={{ color: "#666666", fontSize: 16 }}
              className="mt-xs-5 mt-3 px-sm-2"
            >
              {item.prize_id.points} Puntos
            </p>
            <div>
              <h3>{item.prize_id.title}</h3>
            </div>
            <div>
              <p style={{ color: "#666666", fontWeight: 200, lineHeight: 0.8 }}>
                Usuario: {userName}
              </p>
              <p style={{ color: "#666666", fontWeight: 200, lineHeight: 0.8 }}>
                Identificaci&oacute;n: {identification}
              </p>
            </div>
            <div>
              <p
                style={{
                  color: "#9EA5A7",
                  fontSize: 12,
                  fontWeight: 200,
                  lineHeight: 0.8,
                }}
              >
                Expira: {formatDate(item.expiration_date)}
              </p>
            </div>
          </div>
          <Button
            color="main"
            onClick={onClick}
            style={{ backgroundColor: "#FA720F", color: "#fff" }}
          >
            CANJEAR PREMIO
          </Button>
        </div>
      </Col>
    </Row>
  );
};
