import { useState } from "react";
import { useRef } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Button, Col, Container, Input, Row, Spinner } from "reactstrap";
import logo from "../../assets/images/logo_square.svg";
import { Prize } from "../components/Prize";
import { PrizeRepository } from "../../repositories/prize";
import { PrizeCard } from "../components/PrizeCard";

export const HomePage = () => {
  const [error, setError] = useState(null);
  const [prize, setPrize] = useState(null);
  const [validating, setValidating] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const ref = useRef();

  const handleRedeem = async (id) => {
    setIsRedeeming(true);
    const repository = new PrizeRepository();
    const response = await repository.redeem(id);
    setIsRedeeming(false);
    if (response.status === 200) {
      Swal.fire({
        text: "El premio ha sido canjeado exitosamente",
        icon: "success",
        confirmButton: true,
      }).then(() => {
        setPrize(null);
        setValidating(null);
        ref.current.value = "";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: response.response?.data?.message,
        icon: "error",
        confirmButton: true,
      });
    }
  };

  const handleClick = async () => {
    if (!!!ref.current?.value) {
      setError("Debes introducir el código del premio");
      return;
    }
    setValidating(null);
    setError(null);
    setPrize(null);
    setIsLoading(true);
    const repository = new PrizeRepository();
    try {
      const response = await repository.searchByCode(ref.current.value);
      if (response.status === 200) {
        if (!response.data.redeemed_status) {
          setPrize(response.data);
        } else {
          setError("Este premio ya fue canjeado");
        }
      } else {
        setError(response.response.data.message);
      }
    } catch (e) {
      setError(e.toString());
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center px-4 py-2"
        style={{ borderBottom: "1px solid #666666" }}
      >
        <img src={logo} className="img-fluid" />

        <h3 className="px-3">CANJE DE PREMIOS</h3>
      </div>
      <Container>
        <Row>
          <Col
            sm="12"
            className="h-100 d-flex justify-content-center align-items-center"
            style={{ minHeight: window.innerHeight * 0.8 }}
          >
            <div className="text-center">
              <h1 style={{ color: "#666666" }}>
                <span style={{ color: "#FA720F" }}>Buscar. </span>
                Digita el código del premio.
              </h1>
              <p style={{ color: "#666666" }} className="my-4">
                Solicita a tu cliente el código del premio que quiere canjear
              </p>
              <div
                className="d-flex justify-content-center align-items-start"
                style={{ gap: "20px" }}
              >
                <div className="w-100" style={{ maxWidth: "40rem" }}>
                  <Input
                    placeholder="Digita el Código"
                    className="w-100"
                    innerRef={ref}
                  />
                  <div className="mt-2">
                    {error && <span className="text-danger">{error}</span>}
                  </div>
                </div>

                <Button
                  color="primary"
                  style={{ minWidth: "6rem" }}
                  className="d-flex justify-content-between align-items-center"
                  onClick={handleClick}
                >
                  <span>Buscar</span> <i className="bx bx-search"></i>
                </Button>
              </div>
              <div className="mt-5">
                {isLoading ? (
                  <div className="text-center">
                    <Spinner color="primary"></Spinner>
                  </div>
                ) : null}
                {prize ? (
                  <div style={{ textAlign: "left" }}>
                    <h2 style={{ color: "#666666" }}>Premio Disponible</h2>
                    <hr />
                    <Prize
                      item={prize}
                      onClick={() => {
                        setValidating({ ...prize });
                        setPrize(null);
                      }}
                    />
                  </div>
                ) : null}
                {validating ? (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ color: "#FA720F" }}>Confirmar Canje</h1>
                    <p style={{ color: "#666666" }}>
                      Verifique la información del premio que va a canjear
                    </p>
                    <PrizeCard
                      item={validating}
                      onClick={() => handleRedeem(validating.id)}
                      saving={isRedeeming}
                    />
                    <div className="mt-5 d-flex justify-content-center">
                      <Button
                        onClick={() => {
                          setPrize(validating);
                          setValidating(null);
                        }}
                        color="link"
                        className="justify-content-center align-items-center"
                        style={{ fontSize: 18, mergin: "0 auto" }}
                      >
                        <i className="bx bx-arrow-back"></i>{" "}
                        <span>Regresar</span>
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
