import { configureStore } from "@reduxjs/toolkit";
import prizeReducer from "./slices/prizes";
import authReducer from "./slices/auth";

export const store = configureStore({
  reducer: {
    prize: prizeReducer,
    auth: authReducer,
  },
});
