import { axiosApi } from "../helpers/api_helper";

export class PrizeRepository {
  endpoint = "/prizes";

  async searchByCode(code) {
    return await axiosApi.get(`${this.endpoint}/search/${code}`);
  }

  async redeem(id) {
    return await axiosApi.patch(`${this.endpoint}/${id}/redeem`);
  }
}

export default PrizeRepository;
