import { Button, Card, CardBody, Spinner } from "reactstrap";

export const PrizeCard = ({ item, onClick, saving }) => {
  const userName = `${item.user_id.name || ""} ${item.user_id.last_name || ""}`;

  return (
    <Card className="card-rounded shadow-lg">
      <CardBody className="d-flex flex-column align-items-center">
        <div
          style={{
            maxHeigth: "12rem",
            maxWidth: "12rem",
            padding: "5px",
            border: "1px solid #4F90A2",
          }}
        >
          <img src={item.prize_id.url_logo} className="img-fluid" />
        </div>
        <p
          className="mt-3"
          style={{ color: "#666666", fontSize: 16, lineHeight: 0.8 }}
        >
          {item.prize_id.points} Puntos
        </p>
        <h3>{item.prize_id.title}</h3>
        <p style={{ color: "#666666", fontWeight: 200, lineHeight: 0.8 }}>
          Usuario: {userName}
        </p>
        <p style={{ color: "#666666", fontWeight: 200, lineHeight: 0.8 }}>
          Código: {item.code}
        </p>
        <Button
          color="main"
          className="mt-3 d-flex justify-content-center align-items-center"
          onClick={onClick}
          disabled={saving}
          style={{ backgroundColor: "#FA720F", color: "#fff", gap: "10px" }}
        >
          {saving && <Spinner size="sm"></Spinner>} <span>CANJEAR PREMIO</span>
        </Button>
      </CardBody>
    </Card>
  );
};
